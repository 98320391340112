import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { useHtmlParser } from "../hooks/useHtmlParser"
import { BASE_BREADCRUMBS } from "../constants"
import RequestButton from "../components/RequestButton"

const AboutPage = ({ data }) => {
  let aboutUs = data.allAboutUs.nodes[0]?.content
  return (
    <Layout
      pageTitle="About Us"
      breadcrumbs={[...BASE_BREADCRUMBS, { url: "/about", name: "About" }]}
    >
      <Seo
        title="About Us | Typenex Medical"
        description="About Typenex Medical. Learn more about our blood bands, blood dispensers, and patient verification items."
      />
      <div>
        {useHtmlParser(aboutUs)}
      </div>
      <div className="mt-5 text-center">
        <RequestButton />
      </div>
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query {
    allAboutUs {
      nodes {
        content
      }
    }
  }
`