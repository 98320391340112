import React from 'react'
import { Link } from 'gatsby'
import './styles.scss'

export const RequestButton = ({ node, text }) => {
  return (
    <div role="button" className="request-btn col-12">
      <Link to={`/contact/`} state={{ selectedProduct: { code: node?.salesforce_code, name: node?.name } }} className="btn btn-primary btn-lg">
        Request Sample
      </Link>
    </div>
  )
}

export default RequestButton